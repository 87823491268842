:root {
  --scrollbar-thumb: #d5d5d5;
  --scrollbar-track: #f6f6f6;
  --scrollbar-thumb-hover: #aeaeae;
  --cell-width: 11.25rem;
  --mobile-cell-width: 9.5rem;
  --calendar-border: #e5e5e5;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  -webkit-overflow-scrolling: touch;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea {
  font-family: 'Nunito', sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: 0.4375rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 0.4375rem;
  height: 0.4375rem;
}
*::-webkit-scrollbar-track {
  border-radius: 0.9375rem;
  background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.9375rem;
  background: var(--scrollbar-thumb);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.slide-out-right {
  animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-in-right {
  animation: slide-in-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.rotate-180 {
  transform: rotate(180deg);
}

.mb-1 {
  margin-bottom: 4px;
}

form {
  width: 100%;
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate180 {
  0% {
    transform: rotate(deg);
  }

  100% {
    transform: rotate(1800deg);
  }
}

@keyframes growX {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

.loading {
  opacity: 0.75;
}

[id='sidebar-tooltip'] {
  box-shadow: 2px 2px 2px 0 rgba(28, 27, 27, 0.08);
  border-radius: 8px !important;
}

[id='sidebar-tooltip']::after {
  box-shadow: -2px -2px 2px 0 rgba(28, 27, 27, 0.08);
}

.react-tooltip-arrow {
  filter: drop-shadow(0px 0px 5px 0.08);
}

.rdrDateDisplayWrapper,
.rdrNextPrevButton,
.rdrNextButton,
.rdrPrevButton {
  background: #f9f9f9 !important;
  background-color: #f9f9f9 !important;
}

.rdrDayToday .rdrDayNumber span:after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 18px;
  height: 2px;
  border-radius: 2px;
  background: #f97b5c !important;
}

@media (max-width: 960px) {
  .rbc-time-header .rbc-header {
    min-width: var(--mobile-cell-width);
    border-bottom: none;
  }

  .rbc-time-view-resources,
  .rbc-header {
    min-width: var(--cell-width);
  }

  .rbc-calendar > .rbc-time-content > .rbc-day-bg,
  .rbc-calendar > .rbc-time-view-resources > .rbc-time-content > .rbc-day-slot {
    min-width: var(--mobile-cell-width);
  }

  .rbc-time-column .rbc-timeslot-group {
    min-height: 5.625rem;
  }

  .rbc-time-slot {
    padding-left: 0;
    padding-right: 0;
  }

  .rbc-time-slot > .rbc-label {
    padding: 0 5px;
  }
  .mobile-row-wrap {
    display: flex;
    flex-direction: column;
  }
  .row-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
}

div.google-visualization-tooltip {
  border-radius: 0.5rem;
  transform: translateX(auto);
  font-size: 0.75rem;
  margin: 0;
  width: max-content;
  padding: 0;
}

ul.google-visualization-tooltip-item-list {
  margin: 0;
  width: max-content;
  padding: 0.5rem;
}

li.google-visualization-tooltip-item:first-child {
  padding: 0;
}

li.google-visualization-tooltip-item {
  padding: 0;
  margin: 0;
}

.google-visualization-tooltip path {
  filter: drop-shadow(0px 0px 5px 0.08);
}

.skeleton-wrapper {
  width: 100%;
  height: max-content;
}

.ck-editor__editable > ul,
ol {
  margin-left: 25px;
}

.ck-editor {
  width: 100% !important;
  height: fit-content;
  background: #f9f9f9 !important;
  border-radius: 0.5rem !important;
}

.ck-content,
.ck-focused,
.ck-toolbar {
  border: none !important;
  background-color: inherit !important;
}

.ck-content,
.ck-focused,
.ck-blurred {
  min-height: 200px !important;
}

.ck-toolbar__items > button:last-child {
  width: unset;
  padding: unset;
}

.modal-image {
  object-fit: contain;
  max-width: 100%;
  aspect-ratio: 1/1;
}

.stroke-width-0 {
  path {
    stroke-width: 0;
  }
}

.external-icon {
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  width: 18px;
  height: 18px;
}

@media (max-width: 960px) {
  body > * {
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
  }
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-track {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    display: none;
  }
  .mobile-p-0 {
    padding: 0 !important;
  }
}

.rbc-time-slot {
  background-color: inherit;
  color: inherit;
  border-color: var(--calendar-border) !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-timeslot-group {
  min-height: 6.25rem !important;
  border-color: var(--calendar-border) !important;
  border-bottom: none !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-allday-cell {
  display: none;
  border: none;
}

.rbc-time-header {
  margin-bottom: 0.375rem;
}

.rbc-time-header > * {
  border: none !important;
}

.rbc-row-resource {
  border: none !important;
}

.rbc-calendar > .rbc-time-content > .rbc-day-bg,
.rbc-calendar > .rbc-time-view-resources > .rbc-time-content > .rbc-day-slot {
  min-width: var(--cell-width);
}

.rbc-time-header.rbc-overflowing {
  border: none !important;
}

.rbc-time-content {
  border: none !important;
}

.rbc-time-header-content > .rbc-time-header-cell > .rbc-today,
.rbc-time-content > .rbc-today {
  background-color: initial;
}

.rbc-day-slot:last-child {
  border-right: 1px solid var(--calendar-border);
}

.rbc-day-slot {
  border-bottom: 1px solid var(--calendar-border);
}

.rbc-label {
  color: var(--Grey600, #848484);
  font-weight: 600;
  font-size: 12px;
}

.rbc-header {
  border-bottom: none !important;
}

.rbc-time-view-resources,
.rbc-header {
  min-width: var(--cell-width);
}

.recharts-label {
  text-transform: capitalize;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker__month-text {
  padding: 0.45rem;
}

.react-datepicker__month-text--selected {
  background-color: #fa8e74 !important;
}

.react-datepicker__week:hover .react-datepicker__day {
  border-radius: 3px;
  color: white;
  font-weight: bold;
  background-color: gray;
}

.fill-white path {
  fill: white;
}

.fill-primary path {
  fill: #666;
}

.fill-red-500 path {
  fill: #f86b3f;
}

.br-10 {
  border-radius: 0.625rem;
}

.shadow-accordion {
  box-shadow: 2px 2px 36px 1px #1c1b1b1c;
}

.gap-14 {
  gap: 14px;
}

.text-left {
  text-align: left;
}

.capitalize {
  text-transform: capitalize;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.color-28 {
  color: #282828;
}

.my-0 {
  margin-block: 0;
}

.none {
  display: none;
}

.size-1 {
  width: 1rem;
  height: 1rem;
}

.relative {
  position: relative;
}

.z-index-100 {
  z-index: 100;
}

.items-center {
  align-items: center;
}

.scrollable-content {
  overflow: hidden;
  scrollbar-gutter: stable;
}

.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.accordion-transition {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.recharts-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: center;
  position: relative;
}

.recharts-legend-wrapper {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.recharts-legend-wrapper > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.recharts-legend-wrapper > ul > li {
  white-space: nowrap;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield; /* Firefox */
}

.cursor-pointer {
  cursor: pointer;
}

.p-0 {
  padding: 0 !important;
}

.bg-vid {
  position: fixed;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.bg-transparent {
  background-color: transparent;
}

.outline-none {
  outline: 0;
}

.border-none {
  border: 0;
}
